.disLine {
  position: relative;
  width: 100%;
  height: 100%;
}

.disCuadro {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.disCuadroTransparent {
  background-color: transparent;
  height: 100%;
  z-index: 9999;
}

.disCuadro input{
  word-break: break-all;
}

.disTexto {
  color: #62259d;
  position: relative;
  font-weight: bold;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
