.tablagen{
  text-align: left;
  margin-top: 45px;
  height: 60vh;
  overflow: auto;
}

.espacio {
  padding-right: 40px;
}

.imagen img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  vertical-align: middle !important;
}

.titulotabla {
  width: 100%;
  height: 39px;
  background: #62259d;
  border-bottom: solid 5px rgb(98, 37, 157 , 0.7);
}
.contenido {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #62259d;
  text-align: center;
}
.contenido i {
  font-size: 25px;
}
th {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: white;
}
td {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 14px;
  align-items: center;
  color: #000000;
}
.avatar {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

@media screen and (max-width:960px){
  .tablagen{
    margin-top: 25px;
  }
  .titulotabla {
    height: 30px;
  }
  .prioridad1{
        display: none;
    }
    .contenido {
      height: 35px;
    }
    .tablagen{
      height: 35vh ;
      overflow: auto;
    }
    th {
      font-size: 16px;
    }
    td {
      font-size: 13px;
    }
   }
   @media screen and (max-width:720px){
    .tablagen{
      height: 40vh ;
      overflow: auto;
    }
   }
   @media screen and (max-width:420px){
    .tablagen{
      height: 65vh ;
      overflow: auto;
    }
    th {
      font-size: 14px;
    }
    td {
      font-size: 11px;
    }
    .contenido i {
      font-size: 20px;
    }
    
   }