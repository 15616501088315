.filter {
  margin-top: 100px;
}

.filter input {
  width: 220px;
  font-size: 16px;
  outline: none;
}

.filter select {
  width: 150px;
  margin-left: 0px;
  font-size: 16px;
  outline: none;
}

.filter input:focus,
select:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#date::placeholder {
  color: rgb(98, 37, 157, 0.7);
  text-align: center;
  font-size: 17px;
}

.filter .goFilter {
  background-color: #8f57fd;
}

.filter .goFilter:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.filter i {
  color: white;
}

.btnAdd {
  background: #8f57fd !important;
  border-radius: 5px !important;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  padding-left: 25px;
  padding-right: 25px;
}

@media screen and (max-width: 960px) {
  .filter {
    margin-top: 50px;
  }
  .filter h1 {
    font-size: 25px;
  }
  .filter input {
    font-size: 13px;
  }
  .filter select {
    font-size: 13px;
  }
  #date::placeholder {
    font-size: 15px;
  }
  .filter button {
    font-size: 14px;
  }
}
