.header {
  align-items: center;
  background: #8f57fd;
  color: white;
  display: flex;
  height: 100px;
  justify-content: space-between;
  top: 0px;
  width: 100%;
}

.navbar {
  margin: auto;
  background-color: white;
  height: 80px;
  box-shadow: 0px 4px 5px rgba(98, 37, 157, 0.25);
}
.navbar img {
  width: 150px;
}
.navbar a {
  color: #000000;
}

.imagen .cl{
    width: 40px!important;
    height: 40px!important;
    border-radius: 50%!important;
    vertical-align: middle!important;
}

@media screen and (max-width: 960px) {
  .navbar a {
    color: white;
  }
  .navbar ul {
    width: 50%;
    background-color: #62259d;
    padding-left: 8px;
  }
  .principal {
    width: 650px;
  }
  .navbar li {
    border-bottom: 0.1px solid white;
  }
}

.link-boton{
  border-width: 0px;
  background: transparent;
}

.link-boton:focus{
  border-width: 0px;
  background: transparent;
  outline: none;
}