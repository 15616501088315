.tabla-sm{
    text-align: left;
    height: 35vh;
    overflow: auto;
  }

  .tabla-sm-doc{
    text-align: left;
    height: 15vh;
    overflow: auto;
  }

  .tabla-sm-cal{
    text-align: left;
    height: 28vh;
    overflow: auto;
  }

  .titulotabla-sm {
    width: 100%;
    height: 39px;
    background: #62259d;
    border-bottom: solid 5px rgb(98, 37, 157 , 0.7);
  }
  .th-sm {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    align-items: left;
    text-align: left;
    color: white;
    background: #62259d;
    width: 25px;
  }
  
  .td-sm {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    text-align: left;
    color: #000000;
  }