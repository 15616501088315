.modalprincipal {
  width: 700px;
  height: auto;
  position: relative;
  margin: 4% auto;
  margin-bottom: 0;
  border-radius: 10px;
  background: #eeeeee;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.modalprincipalView {
  width: 1100px;
  height: auto;
  position: relative;
  margin: 4% auto;
  margin-bottom: 0;
  border-radius: 10px;
  background: #eeeeee;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.modalprincipal hr,.modalprincipalView hr {
  margin-top: 13px;
  width: 100%;
  border: 0.7px solid #62259d;
}

.submodal {
  background-color: white;
  width: 100%;
  box-shadow: 0px 4px 5px rgba(98, 37, 157, 0.25);
  height: 50px;
  text-align: center;
}

.submodalTitle {
  padding-top: 2%;
}

.modalcontenido label {
  margin-top: 15px;
  color: #62259d;
  font-weight: bold;
  line-height: 5px;
}
.modalcontenido .campo {
  display: none;
  margin-top: 10px;
  width: 100%;
  height: 80px;
  border: solid 1px black;
  word-wrap: break-word;
}

.mames {
  text-overflow: inherit;
  width: 100%;
  height: 100px;
  padding: 0;
  border-color: #62259d;
  border-width: 2px;
  border-style: dotted;
  display: table;
}

.fileListStore{
  overflow-y: scroll;
  height: 100px;
}
.fileListView{
  padding: 0;
  overflow-y: scroll;
  height: 200px;
}

.modalcontenido {
  margin-top: 9px;
  padding: 10px;
}

.tituloenvio {
  background: #62259d;
  color: white;
  padding-bottom: -25px;
  text-align: center;
}
.subenvio {
  padding: 5px 20px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.envio {
  margin: auto;
  height: 100%;
  border: solid 1px #62259d;
}

.downloadIcon {
  padding-top: 10px;
  font-size: 50px;
}

.downloadIcon .pdf {
  color: red;
}

.downloadIcon .xml {
  color: black;
}
.downloadIcon .pen {
  color: #62259d;
}

.botonmodal {
  margin: auto;
  margin-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.botonmodal button {
  font-family: "Roboto";
  font-size: 20px;
  text-decoration: none;
}

.cancelar {
  color: rgb(194, 0, 0, 0.6);
}
.cancelar:hover {
  color: rgb(194, 0, 0);
}

.aceptar {
  color: #62259d;
}
.aceptar:hover {
  color: #62259d;
}
