body {
  background-color: #EEEEEE;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.principal {
  margin: auto;
  text-align: center;
  width: 1600px;
  margin-top: 85px;
}

.swal2-container {
  z-index: 1000000 !important;
}

@media screen and (max-width: 1400px) {
  .principal {
    width: 85%;
  }
}
